<bit-section>
  <bit-section-header *ngIf="showHeader">
    <h6 bitTypography="h6">{{ "options" | i18n }}</h6>
  </bit-section-header>
  <form class="box" [formGroup]="settings" class="tw-container">
    <div class="tw-mb-4">
      <bit-card>
        <bit-form-field disableMargin>
          <bit-label>{{ "length" | i18n }}</bit-label>
          <input
            bitInput
            formControlName="length"
            type="number"
            [min]="minLength"
            [max]="maxLength"
          />
        </bit-form-field>
      </bit-card>
    </div>
    <div>
      <bit-card>
        <div class="tw-mb-4">{{ "include" | i18n }}</div>
        <div class="tw-flex tw-justify-between">
          <bit-form-control
            class="tw-w-1/5"
            attr.aria-description="{{ 'uppercaseDescription' | i18n }}"
            title="{{ 'uppercaseDescription' | i18n }}"
          >
            <input bitCheckbox type="checkbox" formControlName="uppercase" />
            <bit-label>{{ "uppercaseLabel" | i18n }}</bit-label>
          </bit-form-control>
          <bit-form-control
            class="tw-w-1/5"
            attr.aria-description="{{ 'lowercaseDescription' | i18n }}"
            title="{{ 'lowercaseDescription' | i18n }}"
          >
            <input bitCheckbox type="checkbox" formControlName="lowercase" />
            <bit-label>{{ "lowercaseLabel" | i18n }}</bit-label>
          </bit-form-control>
          <bit-form-control
            class="tw-w-1/5"
            attr.aria-description="{{ 'numbersDescription' | i18n }}"
            title="{{ 'numbersDescription' | i18n }}"
          >
            <input bitCheckbox type="checkbox" formControlName="number" />
            <bit-label>{{ "numbersLabel" | i18n }}</bit-label>
          </bit-form-control>
          <bit-form-control
            class="tw-w-2/5"
            attr.aria-description="{{ 'specialCharactersDescription' | i18n }}"
            title="{{ 'specialCharactersDescription' | i18n }}"
          >
            <input bitCheckbox type="checkbox" formControlName="special" />
            <bit-label>{{ "specialCharactersLabel" | i18n }}</bit-label>
          </bit-form-control>
        </div>
        <div class="tw-flex">
          <bit-form-field class="tw-basis-1/2 tw-mr-4">
            <bit-label>{{ "minNumbers" | i18n }}</bit-label>
            <input
              bitInput
              type="number"
              [min]="minMinNumber"
              [max]="maxMinNumber"
              formControlName="minNumber"
            />
          </bit-form-field>
          <bit-form-field class="tw-basis-1/2">
            <bit-label>{{ "minSpecial" | i18n }}</bit-label>
            <input
              bitInput
              type="number"
              [min]="minMinSpecial"
              [max]="maxMinSpecial"
              formControlName="minSpecial"
            />
          </bit-form-field>
        </div>
        <bit-form-control [disableMargin]="!policyInEffect">
          <input bitCheckbox type="checkbox" formControlName="avoidAmbiguous" />
          <bit-label>{{ "avoidAmbiguous" | i18n }}</bit-label>
        </bit-form-control>
        <p *ngIf="policyInEffect" bitTypography="helper">{{ "generatorPolicyInEffect" | i18n }}</p>
      </bit-card>
    </div>
  </form>
</bit-section>
